import { CSSResult, unsafeCSS } from "lit";

export const defineCustomBorder = (
  namespace = "",
  { borderRadius = "0px", borderStyle = "unset", borderColor = "#000000", borderWidth = "1px" } = {}
): Record<string, string> => {
  const prefix = namespace.startsWith("--beam-") ? namespace : `--beam-${namespace}`;
  return {
    [`${prefix}-borderRadius`]: borderRadius,
    [`${prefix}-borderStyle`]: borderStyle,
    [`${prefix}-borderColor`]: borderColor,
    [`${prefix}-borderWidth`]: borderWidth,
  };
};

export const useCustomBorder = (
  namespace = "",
  { borderRadius = "0px", borderStyle = "unset", borderColor = "#000000", borderWidth = "1px" } = {}
): CSSResult => {
  const prefix = namespace.startsWith("--beam-") ? namespace : `--beam-${namespace}`;
  return unsafeCSS(`\
        border-radius: var(${prefix}-borderRadius, ${borderRadius});
        border-style: var(${prefix}-borderStyle, ${borderStyle});
        border-color: var(${prefix}-borderColor, ${borderColor});
        border-width: var(${prefix}-borderWidth, ${borderWidth});
    `);
};
